import { createRouter, createWebHistory } from 'vue-router'
import GameMain from '../views/game/GameMain'
import GameSlideByIndex from '@/views/game/GameSlideByIndex'
import GameSlideBySession from '@/views/game/GameSlideBySession'
import GameComplete from '@/views/game/GameComplete'
import Extras from '@/views/Extras'
import SelectMission from '@/views/SelectMission'
import Login from '@/views/Login'
import ClassConversation from '../views/ClassConversation'
import nl from '@/utils/language/nl.json'
import apiHandler from '@/utils/handlers/ApiHandler'
import QuizMain from '../views/quiz/QuizMain'
import QuizSlide from '../views/quiz/QuizSlide'
import Error from '@/views/Error'
import GameIntroductionSlide from '@/views/game/GameIntroductionSlide'
import LocalDataHandler from '../utils/handlers/LocalDataHandler'
import { trackRouter } from 'vue-gtag-next'
import { setLayoutBackgroundTo } from '@/utils/styling/backgroundLayout'
import store from '../store'
// import { PARTIAL_LOGIN } from '@/store/mutations'
import { GET_WEEK_STATUS } from '@/store/actions'

export const ROUTE_NAMES = Object.freeze({
  GAME_MISSION: 'GameMission',
  GAME_MEDIA_QUIZ: 'GameMediaQuiz',
  GAME_COMPLETE: 'GameComplete',
  GAME_SLIDE: 'GameSlide',
  GAME_QUIZ_SLIDE: 'GameQuizSlide',
  GAME_INTRODUCTION: 'GameIntroduction',
  EXTRAS: 'Extras',
  LOGIN: 'Login',
  SELECT_MISSION: 'SelectMission',
  CLASS_CONVERSATION: 'ClassConversation',
  GAME_SESSION: 'GameSession',
  DEMO_MISSION: 'DemoMission',
  ERROR: 'Error'
})

const routes = [
  {
    path: '/',
    redirect: ROUTE_NAMES.EXTRAS,
    component: Extras,
    meta: {
      title: 'Game'
    }
  },
  {
    path: '/demo/:id?',
    name: ROUTE_NAMES.DEMO_MISSION,
    meta: {
      availablePreWeek: true
    }
  },
  {
    path: `/${nl.ROUTE.GAME}/${nl.ROUTE.MISSION}/:id/`,
    name: ROUTE_NAMES.GAME_MISSION,
    component: GameMain,
    meta: {
      layoutMode: 'purple'
    },
    children: [
      {
        path: `${nl.ROUTE.SLIDE}/:slideIndex`,
        name: ROUTE_NAMES.GAME_SLIDE,
        component: GameSlideByIndex,
        props: true,
        meta: {
          title: 'Game'
        }
      },
      {
        path: `${nl.ROUTE.CLASS}/:classId`,
        name: ROUTE_NAMES.GAME_SESSION,
        component: GameSlideBySession,
        props: true,
        meta: {
          title: 'Game'
        }
      },
    ],
  },
  {
    path: `/${nl.ROUTE.COMPLETE}/:mission?`,
    name: ROUTE_NAMES.GAME_COMPLETE,
    component: GameComplete,
    props: true,
    meta: {
      title: 'Game'
    }
  },
  {
    path: `/${nl.ROUTE.INTRODUCTION}`,
    name: ROUTE_NAMES.GAME_INTRODUCTION,
    component: GameIntroductionSlide,
    meta: {
      layoutMode: 'blue'
    }
  },
  {
    path: `/${nl.ROUTE.MEDIA_QUIZ}`,
    name: ROUTE_NAMES.GAME_MEDIA_QUIZ,
    component: QuizMain,
    meta: {
      layoutMode: 'blue'
    },
    children: [
      {
        path: ':index?',
        name: ROUTE_NAMES.GAME_QUIZ_SLIDE,
        component: QuizSlide,
        props: true,
        meta: {
          title: 'Game'
        }
      }
    ],
  },
  {
    path: `/${nl.ROUTE.EXTRA_GAMES}`,
    name: ROUTE_NAMES.EXTRAS,
    component: Extras,
    meta: {
      title: 'Game'
    }
  },
  // todo: not based on classId, but on access_token passed from CMS
  {
    path: `/${nl.ROUTE.SELECT_MISSION}/:classId`,
    name: ROUTE_NAMES.SELECT_MISSION,
    component: SelectMission,
    meta: {
      title: 'Game'
    },
    props: true
  },
  {
    path: `/${nl.ROUTE.CLASS_CONVERSATION}`,
    name: ROUTE_NAMES.CLASS_CONVERSATION,
    component: ClassConversation,
    meta: {
      title: 'Game'
    }
  },
  {
    path: `/${nl.ROUTE.LOGIN}`,
    name: ROUTE_NAMES.LOGIN,
    component: Login,
    meta: {
      title: 'Game'
    }
  },
  {
    path: `/${nl.ROUTE.ERROR}`,
    name: ROUTE_NAMES.ERROR,
    component: Error,
    meta: {
      title: 'Error'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function GetDemoMissionRoute(classId = null) {
  let classIdParam = ''
  if (classId) classIdParam = `?schoolClassId=${classId}`

  return apiHandler.api.get(`game/get-current-demo-mission${classIdParam}`).then(response => {
    return response.data
  }).catch(error => {
    return false
  })
}

function getWeekStatus() {
  if (store.getters.getWeekStatus !== {}) {
    return store.getters.getWeekStatus
  }
  return store.dispatch(GET_WEEK_STATUS)
}

router.beforeEach(async(to, from, next) => {
  // console.log('router to', to)
  // console.log('router from', from)

  await getWeekStatus()
  // todo: clean up
  if (to.path !== '/media-quiz' || to.path !== '/game-introduction') {
    // return next({ name: ROUTE_NAMES.GAME_MEDIA_QUIZ })
    if (store.getters.getWeekStatus.status === 'upcoming' && to.name !== ROUTE_NAMES.GAME_SLIDE) {
      const demoMission = await GetDemoMissionRoute()
      return next({ name: ROUTE_NAMES.GAME_SLIDE, params: { id: demoMission.id, slideIndex: 1 } })
    }

    // if (to.query.token) {
    //   store.commit(PARTIAL_LOGIN, to.query.token)
    //   to.fullPath = to.path
    // }

    if (to.query.adminCode) {
      LocalDataHandler.setAdminCode(to.query.adminCode)
      to.fullPath = to.path
    }

    if (to.name === ROUTE_NAMES.DEMO_MISSION) {
      const classId = to.params?.id;
      const demoMission = await GetDemoMissionRoute(classId)
      if (demoMission && demoMission.id) {
        return next({ name: ROUTE_NAMES.GAME_SLIDE, params: { id: demoMission.id, slideIndex: 1 } })
      } else {
        return next({ name: ROUTE_NAMES.EXTRAS })
      }
    }

    if (to.matched.length === 0) {
      return next({ name: ROUTE_NAMES.EXTRAS })
    }
  }

  return next()
})

router.afterEach(async(to, from) => {
  setLayoutBackgroundTo(to.meta.layoutMode) // Set the background mode to the route's defined mode in the meta.
})

trackRouter(router)

export default router
